@import "vars_aurelio";


/******************* DEBUG *******************/
// * { outline: 1px solid red; }
// * * { outline: 1px solid blue; }
// * * * { outline: 1px solid yellow; }
// * * * * { outline: 1px solid green; }
// * * * * * { outline: 1px solid fuchsia; }
// * * * * * * { outline: 1px solid purple; }
// * * * * * * * { outline: 1px solid cadetblue; }
// * * * * * * * * { outline: 1px solid teal; }
/***************** END DEBUG *****************/


body {
    color: $dark-text-color;
}

.btn {
    border-radius: 7px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.inline-block {
    display: inline-block !important;
}

a:focus {
    outline: 0;
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

.panel {
    border-radius: 7px;
    font-size: 16px;
    background-color: $background-light;
    margin-top: 10px;

    &.panel-default {
      &.panel-no-bg {
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &.panel-no-border {
        border: 0px none;
      }
    }
}

.panel-primary>.panel-heading,
.panel-default>.panel-heading {
    /* width: calc(100% + 10px) !important;
    margin: -5px; */
    width: 100%;
    border-radius: 6px 6px 0 0;
    padding: 10px;
    font-size: 18px;
    color: $dark-text-color;
    font-weight: 500;
    border: none;
    text-align: center;
    position: relative;
    display: inline-block;
    background-color: #fff;
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;
}

.panel-primary>.panel-heading:after,
.panel-default>.panel-heading:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0 auto;
    height: 1px;
    background: linear-gradient(90deg, transparent 0%, #56b9f1 50%, transparent 100%);
}


.panel-default,
.panel-primary {
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    border: 1px solid $border-color;
}

.panel-body {
    padding: 20px 30px;
}

.panel-primary {
    border: 1px solid $border-color;
    border-radius: 7px;
    margin-bottom: 10px;
}

.panel-heading h3 {
    font-size: 30px;
    margin: 0;
    padding: 10px 0;
}

.img-responsive {
    border-radius: 7px;
}


.label-success {
    background-color: $main-color;
}

.label-warning {
    background-color: lighten($main-color, 15%);
}

.label-danger {
    background-color: $danger-color;
}


.btn-primary {
    width: 100%;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:focus:active,
    {
    background-image: -webkit-linear-gradient(top, darken($main-color, 10%) 14.13%, $main-color 97.78%);
    background-image: linear-gradient(180deg, darken($main-color, 10%) 14.13%, $main-color 97.78%);
    color: #fff;
    position: relative;
    z-index: 1;
    padding: 14px;
    font-size: 18px;
    outline: 0;
    font-weight: 500;
}

.btn-primary::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: -webkit-linear-gradient(top, $main-color 14.13%, lighten($main-color, 10%) 97.78%);
    background-image: linear-gradient(180deg, $main-color 14.13%, lighten($main-color, 10%) 97.78%);
    z-index: -1;
    transition: opacity 0.3s linear;
    opacity: 0;
    border-radius: 7px;
    padding: 14px;
    font-size: 18px;
    color: #fff;
}

.btn-primary:hover::before {
    opacity: 1;
}

.btn-info {
    z-index: 1;
    padding: 14px;
    outline: 0;
    border: 1px solid darken($background-light, 2%);
    color: lighten($light-text-color, 20%);
    background-color: #fff !important;

}

.btn-info:hover,
.btn-info:focus,
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
    color: darken($background-light, 60%);
    background-color: #fff;
    border-color: darken($background-light, 20%);
    box-shadow: none;
    -webkit-box-shadow: none;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active.focus,
.btn-warning.active:focus,

.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
    color: #fff;
    background-color: $danger-color;
    padding: 14px;
    font-size: 18px;
    outline: 0;
    font-weight: 500;
}

.btn.active:focus,
.btn.focus,
.btn:focus,
.btn:active {
    outline: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

}

.dropdown-menu {
    border-radius: 7px;
    border: none;
    -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    min-width: initial;
}

.dropdown-menu>li>a {
    padding: 5px 13px;
}

.dropdown .dropdown-menu li {
    padding: 2px 5px;
}

.dropdown .dropdown-menu li i {
    color: $icon-color;
}

.dropdown .dropdown-menu li a {
    color: $light-text-color;
    border-radius: 5px;
}

.dropdown .dropdown-menu li a:hover {
    background-color: $background-light;
    cursor: pointer;
}

.dropdown i.fa-ellipsis-v {
    color: $main-color;
    font-size: 22px;
}

.datepicker {

    th:hover,
    td:hover {
        cursor: pointer;
    }
}

.datepicker .day {
    border-radius: 7px;
}

.datepicker .today,
.datepicker .day:hover {
    background-color: $background-light;
    text-align: center;
    border-radius: 7px;
}

.datepicker th.next {
    text-align: right;
}

.datepicker .datepicker-switch,
.datepicker td.day {
    text-align: center;
}

.datepicker .today {
    margin-top: 10px;
}

.datepicker {
    padding: 10px;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
    color: #ffffff;
    background-color: $main-color;
    border-color: transparent;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background: lighten($main-color, 5%);
    text-shadow: none;
}

.datepicker.dropdown-menu {
    border: none;
    color: $light-text-color;
}

.datepicker-orient-left:after,
.datepicker-orient-left:before {
    display: none;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
    background-color: $background-light;
    background-image: none;
    ;
    border: none;
    filter: none;
    color: inherit;
}

.datepicker {

    .datepicker-months,
    .datepicker-years,
    .datepicker-decades,
    .datepicker-centuries {
        text-align: center;
    }
}

.datepicker {

    .datepicker-months .month,
    .datepicker-years .year,
    .datepicker-decades .decade,
    .datepicker-centuries .century {
        margin: 2px 2px;
        display: inline-block;
        padding: 5px;
        border: 1px solid $border-color;
        border-radius: 7px;
        width: 68px;
        text-align: center;
    }
}

.checkbox label {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    margin: 25px 0;
}

.checkbox input[type=checkbox],
input[type=checkbox] {
    display: inline-block;
    width: auto;
    margin: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .04);
    border: 1px solid $border-color;
    background: #FFF;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-right: 8px;
    position: relative;
    outline: 0;

}


input[type=checkbox]:enabled:hover {
    background-size: 80%;
    background-position: center center;
    background-repeat: no-repeat;
    border-color: $main-color;
    background-color: lighten($main-color, 15%);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDIwIj4KICA8cG9seWdvbiBmaWxsPSIjRkZGIiBwb2ludHM9IjI0IDMuNDAxIDkuMzI2IDE5Ljg1OCAwIDEwLjU4NyAzLjM3NCA3LjE4NiA4Ljc1IDEyLjUzNSAyMC4yNyAwIi8+Cjwvc3ZnPgo=);
    outline: 0;
}

input[type=checkbox]:enabled:checked {
    background-size: 80%;
    background-position: center center;
    background-repeat: no-repeat;
    border-color: $main-color;
    background-color: $main-color;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDIwIj4KICA8cG9seWdvbiBmaWxsPSIjRkZGIiBwb2ludHM9IjI0IDMuNDAxIDkuMzI2IDE5Ljg1OCAwIDEwLjU4NyAzLjM3NCA3LjE4NiA4Ljc1IDEyLjUzNSAyMC4yNyAwIi8+Cjwvc3ZnPgo=);
    outline: 0;
}

.float-left {
    float: left;
    position: relative;
}

.vertical-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.margin-5 {
    margin: 5px;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-2 {
    margin-top: 2px !important;
}

.margin-top-10 {
    margin-top: 10px;
}

.no-padding {
    padding: 0 !important;
}

.align-left-right {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
}
.border-b-0::after  {
    background: transparent !important;
}


.grid-2 {
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 10px;
}
.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.w-100 {
    width: 100% !important;
}

.w-49 {
    width: 49% !important;
}

.w-initial {
    width: initial;
}

.padding-0 {
    padding: 0 !important;
}

.padding-2 {
    padding: 2px !important;
}

.padding-5 {
    padding: 5px !important;
}

.padding-6 {
    padding: 0 6px !important;
}

.padding-6v {
    padding: 6px !important;
}

.padding-7 {
    padding: 7px !important;
}

.padding-11 {
    padding: 11px !important;
}

.padding-5-10  {
    padding: 5px 10px !important;
}

.padding-0-15  {
    padding: 0px 15px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-b-2 {
    padding-bottom: 2px !important;
}

.padding-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-lr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.margin-0 {
    margin: 0 !important;
}
.margin-3 {
    margin: 3px !important;
}
.margin-top-0 {
    margin-top: 0 !important;
}

.margin-b-0 {
    margin-bottom: 0 !important;
}

.margin-b-2 {
    margin-bottom: 2px !important;
}

.margin-b-5 {
    margin-bottom: 5px !important;
}

.margin-b-10 {
    margin-bottom: 10px !important;
}

.margin-b-15 {
    margin-bottom: 15px !important;
}

.margin-l-0 {
    margin-left: 0 !important;
}

.margin-l-2 {
    margin-left: 2px !important;
}

.margin-l-5 {
    margin-left: 5px !important;
}

.margin-l-10 {
    margin-left: 10px !important;
}

.margin-5-0-5-0 {
    margin: 5px 0 5px 0 !important;
}

.margin-left-0 {
    margin-left: 0 !important;
}

.margin-lr-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-r-0 {
  margin-right: 0;
}

.float-initial {
    float: initial !important;
}

.right-0 {
    right: 0;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.uppercase {
    text-transform: uppercase;
}

.alert-info {
    color: $dark-text-color;
    background-color: $background-light;
    border: none;

    i {
        color: $danger-color;
    }
}

.jumbotron {
    color: $dark-text-color;
    background-color: $background-light;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    .text-warning {
        color: $dark-text-color;
        ;
    }
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    vertical-align: middle;
}

.table thead,
.table th {
    background-color: $background-light;
}

.table-white>tbody>tr>td,
.table-white th {
    background: #fff;
    font-weight: normal;
}

.table tr:first-child th:first-child {
    border-radius: 7px 0 0 0;
}

.table tr:first-child th:last-child {
    border-radius: 0 7px 0 0;
}

.table tr:first-child th:first-child:last-child {
    border-radius: 7px 7px 0 0;
}

.table tr th {
    border-bottom: none !important;
    border-top: none !important;
}

.table {
    -webkit-box-shadow: 0 0 0 1px #ddd;
    box-shadow: 0 0 0 1px #ddd;
    border-radius: 7px;
}

.table>tbody>tr:first-child>td {
    border-top: none;
}

.alert {
    border-radius: 7px;
}

pre {
    padding: 0;
    margin: 0;
    color: #fff;
    background-color: transparent;
    border: none;
    font-family: inherit;
    white-space: normal;
    font-size: inherit;
}

small {
    color: $icon-color !important;
}

.btn-xs,
.btn-xs:hover,
.btn-xs:focus,
.btn-xs.active.focus,
.btn-xs.active:focus,
.btn-xs.active:hover,
.btn-xs:active.focus,
.btn-xs:active:focus,
.btn-xs:active:hover {
    font-size: 14px;
    padding: 8px;
    font-weight: 500;
}

.title a {
    color: $dark-text-color !important;
    margin-right: 10px;
}

.label:hover {
    color: #fff;
}

.overflow-hidden {
    overflow: hidden;
}

.btn-primary.default {
    filter: grayscale(93%);
}

.btn-primary.danger,
.btn-primary.danger:active,
.btn-primary.danger:focus,
.btn-primary.danger:focus:active,
    {
    background-image: -webkit-linear-gradient(180deg, #e32323 14.13%, #f15656 97.78%);;
    background-image: linear-gradient(180deg, #e32323 14.13%, #f15656 97.78%);
}

.btn-primary.danger::before {
    background-image: -webkit-linear-gradient(180deg, #eb1c1c 14.13%, #ff7e7e 97.78%);
    background-image: linear-gradient(180deg, #eb1c1c 14.13%, #ff7e7e 97.78%);
}


.modal-footer .btn+.btn {
    margin-left: 0;
    margin-top: 5px;
}

.word-break {
    word-break: break-word
}

.absolute {
    position: absolute;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover {
    background-color: $danger-color;
}

.btn-success,
.btn-success:hover,
.btn-success:focus,
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover {
    background-color: $main-color;
}

.flexbox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.container-fluid {
    padding-right: 6px;
}

.pagination.paging {
    margin-top: 50px !important;
}

.pagination.paging>li>a, .pagination.paging>li>span {
    border: none;
    color: darken($border-color, 25%);
    background-color: transparent;
}

.pagination.paging>.active>a, 
.pagination.paging>.active>a:focus, 
.pagination.paging>.active>a:hover, 
.pagination.paging>.active>span, 
.pagination.paging>.active>span:focus, 
.paginpagination.pagingation>.active>span:hover {
    background-color: $main-color;
    border-color: $main-color;
    border-radius: 10px;
    color: #fff;
}

.pagination.paging .disabled {
    color: $border-color;
}

.text-muted {
    color: inherit; 
    opacity: .5;
    text-decoration: line-through;
}

@media (min-width: 768px) {
    .jumbotron {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .jumbotron {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .jumbotron {
        width: 1170px;
    }
}

@media (max-width: 500px) {
    .datepicker {

        .table-condensed {
            width: 70vw;

            thead tr th.dow {
                text-align: center;
            }
        }
    }
}

@media (min-width: 500px) {
    .datepicker {

        .datepicker-months,
        .datepicker-years,
        .datepicker-decades,
        .datepicker-centuries {
            text-align: center;
            max-width: 350px;
        }
    }
}

#qrCodeModal {
  z-index: 100000;

  .modal-dialog {
    max-width: calc(100vw - 20px);
  }
}

.breadcrumbs-xs {
  .dropdown {
    width: calc(100% - 44px);
    display: inline-block;
    margin-left: 2px;
  }

  .dropdown-toggle {
    .btn.btn-info {
      text-overflow: ellipsis;
      overflow-x: hidden;
      max-width: 100%;
      display: block;
    }
  }
  
  .back-button {
    float: left;
    padding: 11px 14px;
  }
}

.cancel-booking-item-form {
  display: inline;
}